<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    offset-y
    min-width="290px"
    :disabled="readOnly"
    @input="onMenuToggle"
  >
    <template #activator="{ on }">
      <v-text-field
        :id="getIdByName('refTextName')"
        :ref="label"
        v-model="outResult"
        :label="label"
        append-icon="mdi-calendar"
        readonly
        :filled="!outlined"
        :outlined="outlined"
        :single-line="singleLine"
        :class="computedTextFieldClasses"
        v-bind="bindParameters"
        :rules="required ? [validationRules.required] : []"
        v-on="on"
      />
    </template>

    <v-card flat>
      <v-row dense>
        <v-col v-if="pickerType === 'date' || pickerType === 'dateTime'">
          <v-date-picker ref="datePicker" v-model="date" scrollable locale="fr" @change="setOutResult($event, 'date')">
          </v-date-picker>
        </v-col>

        <v-col v-if="pickerType === 'time' || pickerType === 'dateTime'">
          <v-time-picker
            ref="timePicker"
            v-model="time"
            format="24hr"
            :allowed-minutes="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]"
            @change="setOutResult($event, 'time')"
          >
          </v-time-picker>
        </v-col>
      </v-row>
      <v-card-actions class="justify-end">
        <v-btn ref="close" text @click="close">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn ref="save" color="primary" :disabled="!hasValidData" text @click="emitDate">
          {{ $t('ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
export default {
  name: 'DateTimePickerField',
  components: {},
  mixins: [translation, accessibility, validationRulesMixin],
  props: {
    label: {
      type: String,
      required: true,
      validator: function (label) {
        return label.length > 0;
      },
    },
    pickerType: {
      type: String,
      required: false,
      default: 'date',
      validator: function (type) {
        return type === 'date' || type === 'time' || type === 'dateTime';
      },
    },
    value: {
      type: String,
      required: false,
      default: null,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    textFieldClasses: {
      type: String,
      required: false,
      default: null,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    singleLine: {
      type: Boolean,
      required: false,
      default: false,
    },
    minDate: {
      type: [Date, String],
      required: false,
      default: undefined,
    },
    maxDate: {
      type: [Date, String],
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      regex: {
        timeHHMMSS: /^\d{2}:\d{2}:\d{2}$/,
        timeHHMM: /^\d{2}:\d{2}$/,
        timeHH: /^\d{2}$/,
        dateYYYYMMDD: /^\d{4}-\d{2}-\d{2}$/,
      },
      date: this.value || '',
      time: this.value || '00:00:00',
      refTextName: null,
      outResult: '',
      menu: false,
    };
  },

  computed: {
    bindParameters: function () {
      return this.required ? this.getRequiredObject() : {};
    },
    hasValidData: function () {
      var result = false;
      var validTime = this.time != null && this.time != '' && this.time.match(this.regex.timeHHMMSS);
      var validDate = this.date != null && this.date != '' && this.date.match(this.regex.dateYYYYMMDD);

      switch (this.pickerType) {
        case 'time':
          result = validTime;
          break;
        case 'dateTime':
          result = validDate && validTime;
          result =
            result &&
            (!this.minDate || new Date(this.minDate) < new Date(this.date + ' ' + this.time)) &&
            (!this.maxDate || new Date(this.maxDate) > new Date(this.date + ' ' + this.time));
          break;
        case 'date':
          result = validDate;
          break;
      }

      return result;
    },
    computedTextFieldClasses() {
      let classes = '';
      if (this.required) {
        classes = 'required-indicator';
      }
      if (this.textFieldClasses) {
        if (classes) {
          classes += ' ';
        }
        classes += this.textFieldClasses;
      }
      return classes;
    },
  },
  watch: {
    value: function () {
      this.init();
    },
  },

  mounted: function () {},
  created: function () {
    this.outResult = '';
    this.init();
  },

  methods: {
    init: function () {
      this.date = this.getDate();
      this.time = this.getTime();
      this.setOutResult(null, null);

      if (this.hasValidData == false) {
        this.outResult = '';
      }
    },

    getDate: function () {
      var result;

      switch (this.pickerType) {
        case 'date':
          result = this.value != null ? this.value : '';
          break;
        case 'dateTime':
          var date = this.value != null ? this.value.split(' ')[0] : '';
          result = date.match(this.regex.dateYYYYMMDD) ? date : '';

          break;
        default:
          result = '';
          break;
      }
      return result;
    },

    getTime: function () {
      var result;

      switch (this.pickerType) {
        case 'time':
          result = this.value != null ? this.value : '';
          break;
        case 'dateTime':
          var splitted = this.value != null ? this.value.split(' ') : [''];
          if (splitted.length == 2) {
            result = this.convertTime(splitted[1]);
          } else {
            result = this.convertTime(splitted[0]);
          }

          break;
        default:
          result = '';
          break;
      }
      return result;
    },

    convertTime: function (value) {
      var result = '00:00:00';

      if (value.match(this.regex.timeHH)) {
        result = value + ':00:00';
      } else if (value.match(this.regex.timeHHMM)) {
        result = value + ':00';
      } else if (value.match(this.regex.timeHHMMSS)) {
        result = value;
      }

      return result;
    },

    setOutResult: function (data, type) {
      switch (type) {
        case 'date':
          this.date = data;
          break;
        case 'time':
          this.time = data;
          break;
      }

      var value = '';
      this.time = this.convertTime(this.time);

      switch (this.pickerType) {
        case 'time':
          value = this.time;
          break;
        case 'dateTime':
          value = this.date + ' ' + this.time;
          break;
        case 'date':
          value = this.date;
          break;
      }

      this.outResult = value;
    },

    emitDate: function () {
      this.$emit('emit-date', this.outResult);
      this.$emit('input', this.outResult);
      this.menu = false;
    },

    onMenuToggle: function (opened) {
      if (opened == false) {
        this.close();
      }
    },

    close: function () {
      this.init();
      this.$emit('close', this.outResult);
      this.menu = false;
    },
  },
};
</script>

<style scoped></style>
