<template>
  <div class="detail-field-container" :class="{ 'detail-field-value--vertical': vertical }">
    <span v-if="label" class="text--secondary text-body-2 detail-field-label mr-2">{{ label }}: </span
    ><span
      v-if="value"
      class="detail-field-value text--primary"
      :class="{ 'detail-field-value--single-line': singleLine }"
      >{{ value }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    singleLine: {
      type: Boolean,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-field-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 8px;
  margin-bottom: 8px;
}

.detail-field-label {
  white-space: nowrap;
}

.detail-field-value {
  white-space: pre-wrap;
}

.detail-field-value--single-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.detail-field-value--vertical {
  flex-direction: column;
}
</style>
